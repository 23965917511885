import { toast } from "vue3-toastify";

const useReferralsInfo = ({ immediate = false }: { immediate?: boolean } = {}) => {
	const { t } = useT();
	const { open } = useAppModals();
	const { copy } = useClipboard();
	const isGuest = useIsGuest();

	const { data, execute } = useAsyncFetch({
		path: "/rest/page/referrals/",
		method: "get",
		options: {
			immediate: immediate && !isGuest.value,
			cached: true,
			watch: [() => isGuest.value],
			default: () => ({
				payload: {
					referralInfo: {
						friendsQualifies: 0,
						friendsInvited: 0,
						entriesSum: 0,
						coinsSum: 0,
						prizeEntries: 0,
						prizeCoins: 0,
						referralUrl: "",
						refcode: "",
						qrCodeUrl: ""
					}
				}
			})
		}
	});

	const referralInfo = computed(() => data.value?.payload?.referralInfo);
	const invitedFriends = computed(() => referralInfo.value?.invitedFriends || []);

	const handleClickCopyLink = () => {
		if (!referralInfo.value?.referralUrl) {
			return;
		}

		copy(referralInfo.value?.referralUrl);
		toast.success(t("Link copied."), {
			icon: h("i", { class: "toast-icon icon-checked" }),
			theme: toast.THEME.DARK,
			position: toast.POSITION.BOTTOM_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 3000
		});
	};

	const handleClickOpenQRCode = () => {
		open("LazyOModalQrcode", { img: referralInfo.value?.qrCodeUrl });
	};

	return {
		referralInfo,
		invitedFriends,
		handleClickCopyLink,
		handleClickOpenQRCode,
		execute
	};
};

export default useReferralsInfo;
